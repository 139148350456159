<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <!-- com -->
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Virtual Cards Users</h2>
      </div>
      <div class="col-span-1 flex gap-1">
        <select
          name=""
          id=""
          class="border border-solid border-blue-200 rounded-sm placeholder-blue-300 outline-none py-3 text-13 text-gray-600 w-[50px] px-1"
          v-model="virtualCards.selectFilter"
        >
          <option value="">No Filter</option>
          <option value="currency">by currency</option>
          <option value="firstName">By firstName</option>
          <option value="lastName">By lastName</option>

          <option value="userId">By userId</option>
        </select>

        <search-form
          v-model="virtualCards.queryValue"
          @submit="(event) => $refs.table.loadAjaxData()"
          v-if="virtualCards.selectFilter"
        />
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :url="`${this.$baseurl}/cards/admin/users`"
        :ajax="true"
        :onClick="goToUser"
        :fillable="true"
        :ajaxPagination="true"
        :data="virtualCards.data"
        :columns="virtualCards.columns"
        :selectFilter="virtualCards.selectFilter"
        :queryValue="virtualCards.queryValue"
        ref="table"
        :sorted="true"
        :dont_sort="virtualCards.dont_sort"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      virtualCards: this.$options.resource([], {
        selectFilter: '',
        queryValue: '',
        dont_sort: ['user'],
        columns: [
          {
            name: 'user',
            th: 'User',
            render: (transaction) => {
              return `${transaction?.firstName} ${transaction?.lastName || ''}`;
            },
          },
          {
            name: 'email',
            th: 'email',
            render: (card) => {
              return `${card?.email || 'N/A'}`;
            },
          },
          {
            name: 'bvn',
            th: 'bvn',
          },
          {
            name: 'idType',
            th: 'idType',
          },
          {
            name: 'idNumber',
            th: 'idNumber',
            render: (id) => id.idNumber || 'N/A',
          },
          {
            name: 'phoneNumber',
            th: 'phoneNumber',
          },

          {
            name: 'address',
            th: 'address',
            render: ({ address }) => address.address,
          },

          {
            name: 'address',
            th: 'state',
            render: ({ address }) => address.state,
          },
          {
            name: 'address',
            th: 'city',
            render: ({ address }) => address.city,
          },
          {
            name: 'created_at',
            th: 'Date Created',
            render: (transaction) => {
              return moment(transaction?.createdAt).format('MMM Do YYYY');
            },
          },
        ],
      }),
    };
  },
  methods: {
    goToUser(user) {
      return this.$router.push({
        name: 'staff-view',
        params: {
          staffId: user?.userId,
        },
      });
    },
  },

  watch: {
    // Watch for changes in selectFilter
    'virtualCards.selectFilter'(newValue) {
      if (!newValue) {
        this.virtualCards.queryValue = ''; // Reset queryValue if no filter is selected
      }
    },
  },
};
</script>
